import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const newsCarousel = document.querySelector('.related-posts__carousel');

  if (newsCarousel) {
    new Swiper(newsCarousel, {
      slidesPerView: 1,
      loop: true,
      navigation: {
        prevEl: '.related-posts__carousel__navigation .swiper-button-prev',
        nextEl: '.related-posts__carousel__navigation .swiper-button-next',
      },
      pagination: {
        el: '.related-posts__carousel__navigation .swiper-pagination',
        type: 'fraction',
      },

      a11y: {
        prevSlideMessage: _x(
          'See previous slide',
          'slider previous label',
          'vtx'
        ),
        nextSlideMessage: _x('See next slide', 'slider next label', 'vtx'),
        paginationBulletMessage: _x(
          'Go to slide {{index}}',
          'slide pagination label',
          'vtx'
        ),
        slideLabelMessage: _x(
          'Slide {{index}} of {{slidesLength}}',
          'slide label',
          'vtx'
        ),
      },

      breakpoints: {
        768: {
          spaceBetween: 40,
          slidesPerView: 2,
        },
        1080: {
          spaceBetween: 40,
          loop: false,
          slidesPerView: 3,
        },
      },
      on: {
        breakpoint: (swiper) => {
          if (1080 < window.innerWidth) {
            swiper.loopDestroy();
          }
        },
      },
    });
  }
});
